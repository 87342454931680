<style lang="scss" scoped>
</style>
<template>

    <div>
        <el-button @click="dialogVisible=true" type="primary" style="margin-bottom: 10px">添加会员</el-button>
        <el-dialog append-to-body title="添加会员" :visible.sync="dialogVisible" width="40%">
            <el-form ref="form" :model="form" :rules="rules" label-width="80px" v-loading="loading">
                <el-form-item label="会员名称">
                    <el-input v-model="form.name" placeholder="请输入会员名称，非必填"></el-input>
                </el-form-item>
                <el-form-item label="会员手机" required prop="mobile">
                    <el-input maxlength="11" v-model="form.mobile" placeholder="请输入手机号，必填"></el-input>
                </el-form-item>
                <el-form-item label="公司名称">
                    <el-input v-model="form.companyName" placeholder="请输入公司名称，非必填"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="handleSubmit" :loading="loading">确 定</el-button>
  </span>
        </el-dialog>
    </div>

</template>

<script type="text/ecmascript-6">
    import {isCellphone} from '@/common/js/Utils'
    import {addCompanyEventUser} from '@/www/urls/memberUrls'
    export default {
        //定义模版数据
        data() {
            return {
                dialogVisible: false,
                loading: false,
                form: {
                    name: '',
                    mobile: '',
                    companyName: ''
                },
                rules: {
                    mobile: [{
                        validator: (rule, value, callback) => {
                            if (!value)
                                callback(new Error('请输入手机号'));
                            else if (!isCellphone(value))
                                callback(new Error('请输入正确的手机号!'));
                            else callback();
                        },
                        trigger:'blur'
                    }]
                }
            }
        },
        //定义事件方法
        methods: {
            initForm(data = {}) {
                const {name = '', mobile = '', companyName = ''} = data || {};
                this.form = {
                    name,
                    mobile,
                    companyName
                }
            },
            async handleSubmit() {
                try {
                    this.loading = true
                    await this.$refs['form'].validate();
                    const ret = await addCompanyEventUser(this.form);
                    if (ret.success&&ret.data) {
                        this.$message.success('添加成功！');
                        this.$emit('onSuccess');
                        this.dialogVisible = false;
                        this.initForm();
                    } else this.$message.error(ret.errors[0].message)
                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    console.log(e);
                }
            }
        }
    }
</script>