<style lang="scss" scoped>
    .container {
        margin: 20px 0;
        background-color: #fff;
        padding: 24px;
        padding-bottom: 0
    }

    .table-container {
        background-color: #fff;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
    }
</style>
<template>
    <el-main class="com-eTrip-section">
        <div class="eTrip-section-cont">
            <div class="container">
                <el-form :inline="true">
                    <el-form-item>
                        <el-input clearable placeholder="手机号" v-model="searchForm.mobile"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="handleRefresh" :loading="loading">搜索</el-button>
                    </el-form-item>
                    <el-form-item>
                        <add-modal @onSuccess="handleRefresh"></add-modal>
                    </el-form-item>
                </el-form>
            </div>
            <div class="table-container">
                <el-table :data="listData.data" style="width: 100%" v-loading="loading">
                    <el-table-column type="index" label="序号"></el-table-column>
                    <el-table-column label="添加时间">
                        <template slot-scope="scope">
                            {{ scope.row.createTime | dateCus }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="会员姓名"></el-table-column>
                    <el-table-column prop="mobile" label="手机号码"></el-table-column>
                    <el-table-column prop="companyName" label="公司名称"></el-table-column>
                    <!--<el-table-column prop="" label="会员状态"></el-table-column>-->
                    <el-table-column label="操作项">
                        <template slot-scope="scope">
                            <el-popconfirm title="确定删除？" @confirm="handleDelete(scope.$index,scope.row)">
                                <el-button slot="reference" size="mini" type="danger" :delLoading="delLoading">
                                    删除
                                </el-button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination :current-page.sync="searchForm.pageIndex"
                               @current-change="getList"
                               :page-size="searchForm.pageSize"
                               layout="total, prev, pager, next"
                               :total="listData.total"></el-pagination>
            </div>
        </div>
    </el-main>
</template>

<script type="text/ecmascript-6">
    import {getCompanyEventUserList, deleteCompanyEventUser} from '@/www/urls/memberUrls'
    import AddModal from './add-modal.vue'
    export default {
        //定义模版数据
        data() {
            return {
                searchForm: {
                    pageIndex: 1,
                    pageSize: 10,
                    mobile: ''
                },
                listData: {
                    data: [],
                    total: 0
                },
                loading: false,
                delLoading: false
            }
        },
        components: {
            AddModal
        },
        //主件被加载完成
        mounted() {
            this.getList();
        },
        //定义事件方法
        methods: {
            async getList() {
                this.loading = true;
                let form = {...this.searchForm};
                if (!form.mobile)
                    delete form.mobile
                const ret = await getCompanyEventUserList(form);
                if (ret.success && ret.data) {
                    this.listData = ret.data;
                } else this.$message.error(ret.errors[0].message)
                this.loading = false;
            },
            handleRefresh() {
                this.searchForm.pageIndex = 1;
                this.getList();
            },
            async handleDelete(index, {id}) {
                this.delLoading = true;
                const ret = await deleteCompanyEventUser({id, isDelete: 1})
                if (ret.success && ret.data) {
                    this.$message.success('删除成功!');
                    this.listData.data.splice(index, 1);
                    this.listData.data.total -= 1;
                } else this.$message.error(ret.errors[0].message)
                this.delLoading = false;
            }
        }
    }
</script>