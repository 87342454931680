/**
 * 会员模块
 */
import http from '@/common/js/http'


/**
 * 增加会员
 * @param form
 */
export const addCompanyEventUser = (form) => http(`/companyEventUser/addCompanyEventUser`, form, 'POST')

/**
 * 会员列表
 * @param form
 */
export const getCompanyEventUserList = (form) => http(`/companyEventUser/companyEventUserList`, form, 'POST');

/**
 * 删除会员
 * @param form
 */

export const deleteCompanyEventUser = (form) => http(`/companyEventUser/deleteCompanyEventUser`, form, 'POST');